<template>
  <b-card class="user-order">
    <b-modal
      id="create-dispute-modal"
      v-model="orderHistoryProps.viewDisputeModal"
      :class="disputeObject ? 'height-auto' : null"
      :title="$t('Dispute Details')"
      :no-close-on-backdrop="true"
      :ok-title="$t('Submit')"
      ok-only
      @hidden="hideDisputeModal"
      @ok="submitServiceDispute"
    >
      <b-col v-if="!disputeObject" cols="12" md="12">
        <b-form-group :label="$t('Dispute Name')" class="price-range-dropdown">
          <v-select v-model="orderHistoryProps.currentDispute" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="disputeOptions" :reduce="val => val.value" :clearable="false" />
        </b-form-group>
        <b-form-group v-if="orderHistoryProps.currentDispute === 'Others'" :label="$t('Dispute Reason')" label-for="disputeReason">
          <b-form-input id="disputeReason" v-model="orderHistoryProps.disputeReason" type="text" />
        </b-form-group>
      </b-col>
      <b-col v-if="disputeObject" cols="12" md="12">
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Dispute Name') }}:</span> {{ orderHistoryProps.disputeObject.dispute_name }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Date') }}:</span> {{ orderHistoryProps.disputeObject.created_time }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Status') }}:</span> {{ orderHistoryProps.disputeObject.status }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Comments By') }}:</span> {{ orderHistoryProps.disputeObject.comments_by }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Comments') }}:</span> {{ orderHistoryProps.disputeObject.comments }}
        </p>
      </b-col>
    </b-modal>
    <b-row>
      <div class="mx-auto order-filters-position col-md-2">
        <b-form-group :label="$t('Filters')">
          <v-select v-model="orderFiltersDropDown" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="orderFilterOptions" :reduce="val => val.value" :clearable="false" />
        </b-form-group>
      </div>
      <div class="col-md-12">
        <good-data-table
          ref="ordersRef"
          :columns="orderColumns"
          :extra-params="extraFilterInput"
          :total-column="'response.responseData.order.total'"
          :data-column="'response.responseData.order.data'"
          :api-endpoint="'/user/trips-history/order'"
          :default-sort-params="{
            field: 'created_time',
            type: 'desc',
          }"
        />
      </div>
    </b-row>
    <b-modal id="orderHistoryShop" v-model="showOrderHistory" :no-close-on-backdrop="true" :title="$t('Order History')" size="xl" ok-only @hidden="showOrderHistory = false">
      <order-history-detail :request-hisory="requestHisory" />
    </b-modal>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BModal, BFormGroup, BFormInput } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import vSelect from 'vue-select'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import OrderHistoryDetail from '@/views/shared/OrderHistoryDetail.vue'
import { useUserUi } from './useUser'

export default {
  components: {
    BRow,
    OrderHistoryDetail,
    BCol,
    GoodDataTable,
    BCard,
    BModal,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  data() {
    return {
      orderFiltersDropDown: 'all',
      extraFilterInput: 'type=all',
      requestHisory: null,
      showOrderHistory: false,
      orderHistoryProps: {
        viewDisputeModal: false,
        currentDispute: '',
        disputeReason: '',
        currentServiceForDispute: {},
        disputeObject: {},
      },
      disputeOptions: [
        {
          label: 'Select',
          value: '',
        },
        {
          label: 'Delivery person was rude',
          value: 'Delivery person was rude',
        },
        {
          label: 'My Promocode Does Not Get Applied',
          value: 'My Promocode Does Not Get Applied',
        },
        {
          label: 'Delivery person picked wrong order and delivered',
          value: 'Delivery person picked wrong order and delivered',
        },
        {
          label: 'My Promocode does not get applied',
          value: 'My Promocode does not get applied',
        },
        {
          label: 'Delivery person delayed pickup',
          value: 'Delivery person delayed pickup',
        },
        {
          label: 'Others',
          value: 'Others',
        },
      ],
    }
  },
  computed: {
    disputeObject() {
      return this.orderHistoryProps.disputeObject && this.orderHistoryProps.disputeObject.id
    },
    orderFilterOptions() {
      return [
        {
          label: this.$t('All'),
          value: 'all',
        },
        {
          label: this.$t('Completed or Cancelled'),
          value: 'past',
        },
        {
          label: this.$t('Upcoming'),
          value: 'upcoming',
        },
        {
          label: this.$t('Others'),
          value: 'current',
        },
      ]
    },
    orderColumns() {
      return [
        {
          label: 'Booking Id',
          field: 'store_order_invoice_id',
        },
        {
          label: 'Date',
          field: 'created_time',
        },
        {
          label: 'Store Name',
          field: 'first_name',
          resolvePath: 'user.first_name',
          useResolver: true,
          useRenderer: true,
          renderer: props => {
            if (props.store) {
              return props.store.store_name
            }
            if (props.invoice && props.invoice.items && props.invoice.items[0] && props.invoice.items[0].store) {
              return props.invoice.items[0].store.store_name
            }
            return 'NA'
          },
        },
        {
          label: 'Total Amount',
          field: 'total_amount',
          rField: 'total_amount_text',
          useResolver: true,
          useRenderer: true,
          renderer: props => {
            if (props.order_type === 'DELIVERY' && props.without_item === 1) {
              return `${props.currency} ${props.cod}`
            }
            return props.total_amount ? `${props.currency} ${props.total_amount}` : ''
          },
        },
        {
          label: 'Payment',
          field: 'payment_mode',
          rField: 'payment_mode_text',
          useRenderer: true,
          useResolver: true,
          renderer: props => props.payment_mode,
        },
        {
          label: 'Status',
          field: 'status',
          align: 'center',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          buttons: [
            {
              image: this.$helpers.getIcons('view'),
              text: 'Order Detail',
              action: props => {
                this.viewBookingDetails(props)
              },
            },
            {
              image: this.$helpers.getIcons('tracking'),
              text: 'View',
              action: props => {
                this.viewOrderDetails(props)
              },
            },
            {
              image: this.$helpers.getIcons('dispute'),
              text: 'Dispute',
              action: props => {
                this.createOrderDispute(props)
              },
            },
          ],
        },
      ]
    },
  },
  watch: {
    orderFiltersDropDown: {
      handler(currentValue) {
        if (currentValue === 'past') {
          this.extraFilterInput = 'type=past'
        } else if (currentValue === 'upcoming') {
          this.extraFilterInput = 'type=upcoming'
        } else if (currentValue === 'current') {
          this.extraFilterInput = 'type=current'
        } else if (currentValue === 'all') {
          this.extraFilterInput = 'type=all'
        }
        this.$refs.ordersRef.loadItems(this.extraFilterInput)
      },
    },
  },
  methods: {
    viewBookingDetails(props) {
      const { getOrderDetailById } = useUserUi()
      showLoader()
      getOrderDetailById(props.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.requestHisory = data.responseData
            this.showOrderHistory = true
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
          hideLoader()
        })
    },
    viewOrderDetails(props) {
      this.$router.push({
        path: `/user/store/order/${props.id}`,
      })
    },
    createOrderDispute(props) {
      this.orderHistoryProps.currentServiceForDispute = props
      this.orderHistoryProps.viewDisputeModal = true
      this.getDisputeStatus()
    },
    hideDisputeModal() {
      this.orderHistoryProps.viewDisputeModal = false
    },
    getDisputeStatus() {
      showLoader()
      this.orderHistoryProps.disputeObject = {}
      const { userOrderDisputeStatus } = useUserUi()
      userOrderDisputeStatus(this.orderHistoryProps.currentServiceForDispute.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.orderHistoryProps.disputeObject = data.responseData
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    submitServiceDispute() {
      const formData = new FormData()
      formData.append('referral_email', '')
      formData.append('dispute_type', 'user')
      formData.append('dispute_name', this.orderHistoryProps.currentDispute)
      formData.append('comments', this.orderHistoryProps.currentDispute === 'Others' ? this.orderHistoryProps.disputeReason : '')
      formData.append('id', this.orderHistoryProps.currentServiceForDispute.id)
      formData.append('user_id', this.orderHistoryProps.currentServiceForDispute.user_id)
      formData.append('store_id', this.orderHistoryProps.currentServiceForDispute.store_id)
      formData.append('provider_id', this.orderHistoryProps.currentServiceForDispute.provider_id)

      const { createOrderDispute } = useUserUi()
      showLoader()
      createOrderDispute(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.vs__dropdown-toggle {
  border: 1px solid #d8d6de;
}

#create-dispute-modal .modal-body {
  height: 260px;
}

#create-dispute-modal.height-auto .modal-body {
  height: auto;
}

.order-filters-position {
  position: absolute;
  z-index: 9;
  left: 150px;
  top: 20px;
}
</style>

<style scoped>
@media only screen and (max-width: 767px) {
  .order-filters-position {
    position: static;
  }
}
</style>
